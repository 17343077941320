.google-meet-clone {
    display: flex;
    flex-direction: column;
    height: 60vh;
    background-color: #000;
}

.video-grid {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 10px;
    padding: 10px;
    background-color: #111;
}

.local-video,
.remote-video {
    width: 100%;
    height: auto;
    background-color: #222;
    border-radius: 10px;
}

.controls {
    display: flex;
    justify-content: center;
    padding: 10px;
    background-color: #333;
}

.button-call {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    margin: 0 20px;
    cursor: pointer;
}

.button-call:hover {
    color: #aaa;
}